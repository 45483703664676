export function getApiSecretKey() {
    return process.env.REACT_APP_API_SECRET_KEY;
  }
  
  export function getApiBase() {
    return process.env.REACT_APP_API_BASE_ADMIN;
  }

  export function getApiBaseClient() {
    return process.env.REACT_APP_API_BASE_CLIENT;
 }
