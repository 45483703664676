import React from 'react';
import CryptoJS from 'crypto-js';
import { getApiSecretKey } from '../../env';

const secretKey = getApiSecretKey();


export const encryption = (str) => {
  if (!str) {
    return ""; // Return empty string if input is empty
  }
  try {
    return CryptoJS.AES.encrypt(str, secretKey).toString();
  } catch (error) {
    console.error("Error during encryption:", error);
    return '';
  }
};

// export const decryption = (str) => {
//   return CryptoJS.AES.decrypt(str, secretKey).toString();
// }

export const decryption = (str) => {
  try {
    if (str) {
      const bytes = CryptoJS.AES.decrypt(str, secretKey);

      if (!bytes) {

        // Handle decryption failure gracefully
        return '';
      }
      const decryptedData = bytes.toString(CryptoJS.enc.Utf8);

      return decryptedData;
    }
  } catch (error) {
    console.error("Error during decryption123:", error);
    // Handle decryption error gracefully, e.g., by returning an empty string
    return '';
  }
}


