import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Maintenancemode from "../Pages/Maintenancemode";
import { IoChevronDown } from "react-icons/io5";
const Sidebar = () => {
  const [submenuVisible, setSubmenuVisible] = useState(false);
  const [isMaintenanceMode, setIsMaintenanceMode] = useState(false);
  const [subreportVisible, setSubreportVisible] = useState(false);
  const location = useLocation();
  const menuItems = [
    { path: "/userreports", label: "User" },
    { path: "/stationreports", label: "Station" },
    { path: "/areareports", label: "Area" },
    { path: "/battriesreport", label: "Batteries" },
    { path: "/subscriptionreports", label: "Subscription" },
    { path: "/customerreports", label: "Customers" },
    { path: "/dealerreports", label: "Dealers" },
    { path: "/colorreports", label: "Color" },
    { path: "/vehiclereports", label: "Vehicle" },
    { path: "/rolereports", label: "Coupon" },
    { path: "/swapreports", label: "Battery Swap" },
  ];
  const toggleSubmenu = () => {
    setSubmenuVisible(!submenuVisible);
  };

  const toggleReportMenu = () => {
    setSubreportVisible(!subreportVisible);
  };

  useEffect(() => {
    const savedState = localStorage.getItem("isMaintenanceMode");
    if (savedState) {
      setIsMaintenanceMode(JSON.parse(savedState));
    }
  }, []);

  // const getLinkClass = (path) => {
  //     // Check if the current location starts with the path
  //     return location.pathname.startsWith(path) ? 'active-menu-item' : '';
  // };

  const getLinkClass = (path) => {
    const pathname = location.pathname;

    // Check if the path matches the current location
    if (pathname.startsWith(path)) {
      return "active-menu-item";
    }

    // Specific checks for paths that might overlap with others
    if (path === "/subscription" && pathname.includes("/editsubscription")) {
      return "active-menu-item";
    }
    if (path === "/NewCustomer") {
      return "active-menu-item";
    }

    if (
      path === "/station" &&
      (pathname.includes("/Stationadd") ||
        pathname.includes("/editstation") ||
        pathname.includes("/Stationbattery"))
    ) {
      return "active-menu-item";
    }
    if (
      path === "/Coupen" &&
      (pathname.includes("/Stationadd") ||
        pathname.includes("/Addcoupen") ||
        pathname.includes("/Addcouponedit"))
    ) {
      return "active-menu-item";
    }

    if (
      path === "/customermanagement" &&
      (pathname.includes("/addcustomer") ||
        pathname.includes("/editcustomer") ||
        pathname.includes("/Viewvehicle") ||
        pathname.includes("/vehicleassign"))
    ) {
      return "active-menu-item";
    }

    if (
      path === "/dealerlist" &&
      (pathname.includes("/dealerlogin") ||
        pathname.includes("/dealeredit") ||
        pathname.includes("/assignbatteries"))
    ) {
      return "active-menu-item";
    }

    if (
      path === "/userlist" &&
      (pathname.includes("/useredit") || pathname.includes("/useradd"))
    ) {
      return "active-menu-item";
    }

    if (
      path === "/batterylist" &&
      (pathname.includes("/batteryedit") || pathname.includes("/batteryadd"))
    ) {
      return "active-menu-item";
    }
    if (
      path === "/customermanagement" &&
      (pathname.includes("/NewCustomer") || pathname.includes("/EditNewCus"))
    ) {
      return "active-menu-item";
    }

    if (
      path === "/arealist" &&
      (pathname.includes("/areadd") || pathname.includes("/areaedit"))
    ) {
      return "active-menu-item";
    }
    if (path === "/rolelist" && pathname.includes("/roleedit")) {
      return "active-menu-item";
    }

    if (
      path === "/vehicleregisterlist" &&
      (pathname.includes("/vehicleregister") ||
        pathname.includes("/vehicleregisteredit"))
    ) {
      return "active-menu-item";
    }

    if (
      path === "/vehiclemodellist" &&
      (pathname.includes("/vehiclemodeledit") ||
        pathname.includes("/vehiclemodel"))
    ) {
      return "active-menu-item";
    }

    if (
      path === "/vehiclecolorlist" &&
      (pathname.includes("/vehiclecoloredit") ||
        pathname.includes("/vehiclecolor"))
    ) {
      return "active-menu-item";
    }
    if (
      path === "/vehicletypelist" &&
      (pathname.includes("/vehicletypeedit") ||
        pathname.includes("/vehicletype"))
    ) {
      return "active-menu-item";
    }
    if (
      path === "/colorlist" &&
      (pathname.includes("/coloredit") || pathname.includes("/color"))
    ) {
      return "active-menu-item";
    }
    // Reports
    if (path === "/userreports") {
      return "active-menu-item";
    }
    if (path === "/battriesreport") {
      return "active-menu-item";
    }
    if (path === "/areareports") {
      return "active-menu-item";
    }
    if (path === "/stationreports") {
      return "active-menu-item";
    }
    if (path === "/subscriptionreports") {
      return "active-menu-item";
    }
    if (path === "/customerreports") {
      return "active-menu-item";
    }
    if (path === "/dealerreports") {
      return "active-menu-item";
    }
    if (path === "/colorreports") {
      return "active-menu-item";
    }
    if (path === "/vehiclereports") {
      return "active-menu-item";
    }
    if (path === "/rolereports") {
      return "active-menu-item";
    }
    if (path === "/swapreports") {
      return "active-menu-item";
    }
    return "";
  };

  return (
    <>
      <aside className="sidebar text-white text-start bg-gray position-fixed col-2">
        <ul className="list-unstyled">
          <h4 className="activity" style={{ color: "#2f6f92" }}>
            My activity
          </h4>
          <li className={`custom-list-item ${getLinkClass("/dashboard")}`}>
            <Link to="/dashboard" className="text-decoration-none custom-link">
              <span className="icon-cont">
                <i class="fa-solid fa-house"></i>
              </span>
              <span className="side-title">Dashboard</span>
            </Link>
          </li>

          <li
            className={`custom-list-item ${
              getLinkClass("/userlist") ||
              location.pathname.includes("/useradd", "/useredit")
                ? "active-menu-item"
                : ""
            }`}
          >
            <Link to="/userlist" className="text-decoration-none custom-link">
              <span className="icon-cont">
                <i className="fa-solid fa-user side-icn" />
              </span>
              <span className="side-title">Users</span>
            </Link>
          </li>
          <li
            className={`custom-list-item ${
              getLinkClass("/batterylist") ||
              location.pathname.includes("/batteryadd", "/batteryedit")
                ? "active-menu-item"
                : ""
            }`}
          >
            <Link
              to="/batterylist"
              className="text-decoration-none custom-link"
            >
              <span className="icon-cont">
                {" "}
                <i className="fa-solid fa-battery-full side-icn" />
              </span>{" "}
              <span className="side-title ">Batteries</span>
            </Link>
          </li>
          <li
            className={`custom-list-item ${
              getLinkClass("/arealist") ||
              location.pathname.includes("/areaadd")
                ? "active-menu-item"
                : ""
            }`}
          >
            <Link to="/arealist" className="text-decoration-none custom-link">
              <span className="icon-cont">
                <i className="fa-solid fa-map-marker-alt side-icn" />
              </span>
              <span className="side-title ">Area</span>
            </Link>
          </li>
          <li
            className={`custom-list-item ${
              getLinkClass("/station") ||
              location.pathname.includes("/Stationadd")
                ? "active-menu-item"
                : ""
            }`}
          >
            <Link to="/station" className="text-decoration-none custom-link">
              <span className="icon-cont">
                {" "}
                <i className="fa-solid fa-charging-station side-icn" />
              </span>{" "}
              <span className="side-title ">Station</span>
            </Link>
          </li>
          <li
            className={`custom-list-item ${
              getLinkClass("/subscription") ||
              location.pathname.includes("/addsubscription")
                ? "active-menu-item"
                : ""
            }`}
          >
            <Link
              to="/subscription"
              className="text-decoration-none custom-link"
            >
              <span className="icon-cont">
                <i className="fa-solid fa-bell side-icn"></i>
              </span>
              <span className="side-title ">Subscription</span>
            </Link>
          </li>
          <li
            className={`custom-list-item ${
              getLinkClass("/customermanagement") ||
              location.pathname.includes("/addcustomer", "/editcustomer")
                ? "active-menu-item"
                : ""
            }`}
          >
            <Link
              to="/customermanagement"
              className="text-decoration-none custom-link"
            >
              <span className="icon-cont">
                {" "}
                <i className="fa-regular fa-address-card side-icn"></i>
              </span>{" "}
              <span className="side-title ">Customer</span>
            </Link>
          </li>
          <li
            className={`custom-list-item ${
              getLinkClass("/dealerlist") ||
              location.pathname.includes("/dealerlogin")
                ? "active-menu-item"
                : ""
            }`}
          >
            <Link to="/dealerlist" className="text-decoration-none custom-link">
              <span className="icon-cont">
                <i className="fa-solid fa-file-invoice side-icn" />
              </span>{" "}
              <span className="side-title ">Dealer</span>
            </Link>
          </li>
          <li
            className={`mt-2 custom-list-item ${
              getLinkClass("/colorlist") ? "active-menu-item" : ""
            }`}
          >
            <Link to="/colorlist" className="text-decoration-none custom-link">
              <span className="icon-cont">
                <i className="fa-solid fa-user side-icn" />
              </span>{" "}
              <span className="side-title ">Color</span>
            </Link>
          </li>

          {/* Vehicle */}
          <li
            className={`mt-2 custom-list-item ${
              submenuVisible ||
              location.pathname.includes("/vehiclemodellist") ||
              location.pathname.includes("/vehiclemodel") ||
              location.pathname.includes("/vehiclemodeledit") ||
              location.pathname.includes("/vehicletypelist") ||
              location.pathname.includes("/vehicletype") ||
              location.pathname.includes("/vehicletypeedit") ||
              location.pathname.includes("/vehiclecolorlist") ||
              location.pathname.includes("/vehiclecolor") ||
              location.pathname.includes("/vehiclecoloredit") ||
              location.pathname.includes("/vehicleregisterlist") ||
              location.pathname.includes("/vehicleregister") ||
              location.pathname.includes("/vehicleregisteredit")
                ? "active-menu-item submenu-open"
                : ""
            }`}
          >
            <Link
              className="text-decoration-none custom-link"
              onClick={toggleSubmenu}
            >
              <span className="icon-cont">
                <i className="fa-solid fa-motorcycle"></i>
              </span>
              <span className="side-title">Vehicle</span>
              <span className="chevron-icon">
                <IoChevronDown />
              </span>
            </Link>
            {(submenuVisible ||
              location.pathname.includes("/vehiclemodellist") ||
              location.pathname.includes("/vehiclemodel") ||
              location.pathname.includes("/vehiclemodeledit") ||
              location.pathname.includes("/vehicletypelist") ||
              location.pathname.includes("/vehicletype") ||
              location.pathname.includes("/vehicletypeedit") ||
              location.pathname.includes("/vehiclecolorlist") ||
              location.pathname.includes("/vehiclecolor") ||
              location.pathname.includes("/vehiclecoloredit") ||
              location.pathname.includes("/vehicleregisterlist") ||
              location.pathname.includes("/vehicleregister") ||
              location.pathname.includes("/vehicleregisteredit")) && (
              <ul className="submenu">
                {/* Vehicle Model List */}
                <li
                  className={`submenu-item ${
                    getLinkClass("/vehiclemodellist") ||
                    location.pathname.includes("/vehiclemodeledit") ||
                    location.pathname.includes("/vehiclemodel")
                      ? "active-menu-item"
                      : ""
                  }`}
                >
                  <Link
                    to="/vehiclemodellist"
                    className={`text-decoration-none text-white ${getLinkClass(
                      "/vehiclemodellist"
                    )}`}
                  >
                    Vehicle Model
                  </Link>
                </li>
                {/* Vehicle Type List */}
                <li
                  className={`submenu-item ${
                    getLinkClass("/vehicletypelist") ||
                    location.pathname.includes("/vehicletypeedit") ||
                    location.pathname.includes("/vehicletypeadd")
                      ? "active-menu-item"
                      : ""
                  }`}
                >
                  <Link
                    to="/vehicletypelist"
                    className={`text-decoration-none text-white ${getLinkClass(
                      "/vehicletypelist"
                    )}`}
                  >
                    Vehicle Type
                  </Link>
                </li>
                {/* Vehicle Color List */}
                <li
                  className={`submenu-item ${
                    getLinkClass("/vehiclecolorlist") ||
                    location.pathname.includes("/vehiclecoloredit") ||
                    location.pathname.includes("/vehiclecoloradd")
                      ? "active-menu-item"
                      : ""
                  }`}
                >
                  <Link
                    to="/vehiclecolorlist"
                    className={`text-decoration-none text-white ${getLinkClass(
                      "/vehiclecolorlist"
                    )}`}
                  >
                    Vehicle Color
                  </Link>
                </li>
              </ul>
            )}
          </li>

          {/* Reports */}
          <li
            className={`mt-2 custom-list-item ${
              subreportVisible ||
              location.pathname.includes("/userreports") ||
              location.pathname.includes("/stationreports") ||
              location.pathname.includes("/areareports") ||
              location.pathname.includes("/battriesreport") ||
              location.pathname.includes("/subscriptionreports") ||
              location.pathname.includes("/customerreports") ||
              location.pathname.includes("/dealerreports") ||
              location.pathname.includes("/colorreports") ||
              location.pathname.includes("/vehiclereports") ||
              location.pathname.includes("/rolereports") ||
              location.pathname.includes("/swapreports")
                ? "active-menu-item submenu-open"
                : ""
            }`}
          >
            <Link
              className="text-decoration-none custom-link"
              onClick={toggleReportMenu}
            >
              <span className="icon-cont">
                <i className="fa-solid fa-file-excel"></i>
              </span>
              <span className="side-title">Reports</span>
              <span className="chevron-icon">
                <IoChevronDown />
              </span>
            </Link>
            {(subreportVisible ||
              location.pathname.includes("/userreports") ||
              location.pathname.includes("/stationreports") ||
              location.pathname.includes("/areareports") ||
              location.pathname.includes("/battriesreport") ||
              location.pathname.includes("/subscriptionreports") ||
              location.pathname.includes("/customerreports") ||
              location.pathname.includes("/dealerreports") ||
              location.pathname.includes("/colorreports") ||
              location.pathname.includes("/vehiclereports") ||
              location.pathname.includes("/rolereports") ||
              location.pathname.includes("/swapreports")) && (
              <ul className="submenu">
                {menuItems.map((item) => (
                  <li
                    key={item.path}
                    className={`submenu-item ${
                      location.pathname === item.path
                        ? "active-submenu-item"
                        : ""
                    }`}
                  >
                    <Link
                      to={item.path}
                      className="text-decoration-none text-white"
                      onClick={(e) => e.stopPropagation()} // Prevent click from closing dropdown
                    >
                      {item.label}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </li>
          <li
            className={`custom-list-item ${
              getLinkClass("/rolelist") ? "active-menu-item" : ""
            }`}
          >
            <Link to="/rolelist" className="text-decoration-none custom-link">
              <span className="icon-cont">
                <i className="fa-solid fa-user side-icn" />
              </span>{" "}
              <span className="side-title ">Role</span>
            </Link>
          </li>
          {/* <li
            className={`custom-list-item ${getLinkClass("/maintenence")}`}
            style={{ marginTop: "5px" }}
          >
            <Link
              to="/maintenence"
              className="text-decoration-none custom-link"
            >
              <span className="icon-cont">
                <i className="fa-solid fa-tools side-icn" />
              </span>

              <span className="side-title">CMS</span>
            </Link>
          </li> */}
          <li
            className={`custom-list-item ${getLinkClass("/Coupen")}`}
            style={{ marginTop: "5px" }}
          >
            <Link to="/Coupen" className="text-decoration-none custom-link">
              <span className="icon-cont">
                <i class="fa-solid fa-dollar-sign"></i>
              </span>
              {/* <i className="fa-solid fa-tools side-icn" /></span> */}

              <span className="side-title">Coupon</span>
            </Link>
          </li>
          <li className={`custom-list-item ${getLinkClass("/logout")}`}>
            <Link to="/logout" className="text-decoration-none custom-link">
              <span className="icon-cont">
                <i className="fa-solid fa-arrow-right-from-bracket me-3 side-icn" />
              </span>
              <span className="side-title">Log Out</span>
            </Link>
          </li>
        </ul>
      </aside>
      {isMaintenanceMode && <Maintenancemode />}
    </>
  );
};
export default Sidebar;
