import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const Logout = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const handleLogout = () => {
            Swal.fire({
                title: 'Are you sure?',
                text: 'You will be logged out of your account.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3F6F92',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes,logout !',
                cancelButtonText: 'No, stay logged in',
            }).then((result) => {
                if (result.isConfirmed) {
                    // Clear cookies and local storage
                    Cookies.remove('Username');
                    Cookies.remove('Password');
                    Cookies.remove('previousRoute'); // Clear the stored route on logout
                    Cookies.remove('token'); // Remove the auth token from cookies
                    localStorage.clear();

                    // Display logout success message
                    Swal.fire({
                        title: 'Logged out!',
                        text: 'You have been logged out successfully.',
                        icon: 'success',
                        timer: 1000, // 3 seconds
                        showConfirmButton: false,
                    });

                    setTimeout(() => {
                        navigate('/'); 
                    },1000);
                } else {
                    const previousRoute = Cookies.get('previousRoute') || '/';
                    navigate(previousRoute);
                }
            });
        };

        handleLogout();
    }, [navigate]);

    return null;
};

export default Logout;
