// import React from 'react';
// import { Navigate } from 'react-router-dom';
// import Cookies from "js-cookie";

// const PersistedRoute = ({ element }) => {
//     const isAuthenticated = true; // Replace with actual authentication check
//     if (isAuthenticated) {
//         // Check for a stored route
//         const previousRoute = Cookies.get('previousRoute');
//         if (previousRoute) {
//             Cookies.remove('previousRoute');
//             return <Navigate to={previousRoute} />;
//         }
//         return element;
//     } else {
//         // Redirect to login page or other
//         return <Navigate to="/" />;
//     }
// };
// export default PersistedRoute;
import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import Cookies from "js-cookie";

const PersistedRoute = ({ element }) => {
    const location = useLocation();

    useEffect(() => {
        // Store the current path in cookies, except for '/', '/logout', and '/forgotpass'
        if (location.pathname !== '/' && location.pathname !== '/logout' && location.pathname !== '/forgotpass') {
            Cookies.set('previousRoute', location.pathname, { expires: 7 });
        }
    }, [location.pathname]);

    let pageId;

    if (location.pathname === "/dashboard") {
        pageId = 1;

    } else if (location.pathname === "/useradd") {
        pageId = 2;

    } else if (location.pathname === "/useredit/:id") {
        pageId = 3;

    } else if (location.pathname === "/userlist") {
        pageId = 4;

    } else if (location.pathname === "/batteryadd") {
        pageId = 5;

    } else if (location.pathname === "/batterylist") {
        pageId = 6;

    } else if (location.pathname === "/batteryedit/:id") {
        pageId = 7;

    } else if (location.pathname === "/areaadd") {
        pageId = 8;

    } else if (location.pathname === "/areaedit/:id") {
        pageId = 9;

    } else if (location.pathname === "/arealist") {
        pageId = 10;

    } else if (location.pathname === "/station") {
        pageId = 11;

    } else if (location.pathname === "/Stationadd") {
        pageId = 12;
    }  else if (location.pathname === "/editstation/:id") {
        pageId = 13;
    } else if (location.pathname === "/Stationbattery") {
        pageId = 14;
    } else if (location.pathname === "/subscription") {
        pageId = 15;
    } else if (location.pathname === "/addsubscription") {
        pageId = 16;
    } else if (location.pathname === "/editsubscription/:id") {
        pageId = 17;
    } else if (location.pathname === "/customermanagement") {
        pageId = 18;
    } else if (location.pathname === "/addcustomer") {
        pageId = 19;
    } else if (location.pathname === "/editcustomer/:id") {
        pageId = 20;
    } else if (location.pathname === "/vehicleassign") {
        pageId = 21;
    }else if (location.pathname === "/vehicleassign/:id") {
        pageId = 22;
    }else if (location.pathname === "/Viewvehicle") {
        pageId = 23;
    }else if (location.pathname === "/dealerlist") {
        pageId = 24;
    }else if (location.pathname === "/dealerlogin") {
        pageId = 25;
    }else if (location.pathname === "/dealeredit/:id") {
        pageId =26;
    }else if (location.pathname === "/assignbatteries") {
        pageId = 27;
    }else if (location.pathname === "/vehiclemodel") {
        pageId = 28;
    }else if (location.pathname === "/vehiclemodeledit/:id") {
        pageId = 29;
    }else if (location.pathname === "/vehiclemodellist") {
        pageId = 30;
    }else if (location.pathname === "/coloredit/:id") {
        pageId = 31;
    }else if (location.pathname === "/coloradd") {
        pageId = 32;
    }else if (location.pathname === "/colorlist") {
        pageId = 33;
    }else if (location.pathname === "/maintenence") {
        pageId = 34;
    }else if (location.pathname === "/Addmaintenence") {
        pageId = 35;
    }else if (location.pathname === "/Editmaintenence/:id") {
        pageId = 36;
    }else if (location.pathname === "/Coupen") {
        pageId = 37;
    }else if (location.pathname === "/Addcouponedit/:id") {
        pageId = 38;
    }else if (location.pathname === "/Addcoupen") {
        pageId = 39;
    }else if (location.pathname === "/myprofile") {
        pageId = 40;
    }else if (location.pathname === "/vehicletype") {
        pageId = 41;
    }else if (location.pathname === "/vehicletypeedit/:id") {
        pageId = 42;
    }else if (location.pathname === "/vehicletypelist") {
        pageId = 43;
    }

    let isAccess = true; // Replace with actual authentication check

    if (isAccess) {

        return element;
    } else {
        return <Navigate to="/notaccess" />;
    }
};

export default PersistedRoute;























